import React from 'react'
import profilePic from '../../assets/images/profile.jpg';
import reactLogo from '../../assets/images/logos/react.png';
import typescriptLogo from '../../assets/images/logos/typescript.png';
import nodejsLogo from '../../assets/images/logos/nodejs.png';
import javascriptLogo from '../../assets/images/logos/javascript.png';
import gitLogo from '../../assets/images/logos/git.png';
import './Competences.scss';
import Skill from './components/Skill';

interface Props {
    sectionId: number;
}

export default function CompetencesPage(props: Props) {
    return (
        <div className="page" id="competences-page" data-sectionid={props.sectionId}>
            <img
                src={profilePic}
                id="main-circle"
                alt="Alexandre Gaubert, développeur web freelance react javascript, au milieu de ses compétences en typescript, HTML, CSS, react, Git"
            />
            <Skill
                top={20}
                left={20}
                logoUrl={reactLogo}
                size={125}
                title='ReactJS'
                description={`Le framework Javascript qu'on ne présente plus, celui avec lequel j'ai appris le développement web.
                
                Fort de près de quatre années d'expérience sur cette technologie front-end, j'en ai fait ma spécialité.`}
            />
            <Skill
                top={80}
                left={80}
                logoUrl={typescriptLogo}
                size={125}
                title='Typescript'
                description={`Typescript est une surcouche de Javascript, il permet d'écrire un code robuste et aide à limiter les erreurs de développement.
                
                Incoutournable, je l'utilise depuis près de trois ans en milieu professionnel.`}
            />
            <Skill
                top={60}
                left={15}
                logoUrl={nodejsLogo}
                title='NodeJS'
                description={`Le framework back-end écrit en JavaScript et très largement utilisé de nos jours. 
                
                Fort d'une expérience personnelle sur cette technologie, j'y suis à l'aise bien que ce n'est pas ma spécialité.`}
            />
            <Skill
                top={15}
                left={80}
                logoUrl={javascriptLogo}
                title='JavaScript'
                description={`Peu utilisé, il y a encore quelques années, aujourd'hui la quasi-totalité des technologies front-end reposent dessus.
                
                En tant que dévelopeur front, c'est le premier langage que je dois maîtriser.`}
            />
            <Skill
                top={15}
                left={55}
                logoUrl={gitLogo}
                title='Git'
                description={`L'incontournable gestionnaire de code source. 
                
                Je m'en suis toujours servi pour tous mes projets en équipe depuis l'école, et jusqu'à aujourd'hui, en milieu professionnel.`}
            />
        </div>
    )
}