export enum WindowCorner {
    TopLeft,
    TopRight,
    BottomLeft,
    BottomRight
}

export type LinePosition = {
    angle: number,
    distance: number,
    left: number,
    top: number
}

export type SkillPosition = {
    left: number;
    top: number;
}